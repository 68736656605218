//mediabank-library
.mediabank-library {
    //FORM
    //Search-help-button
    .mediabank-search .mediabank-search-help {
        background: $color-blue;
        border:1px solid #fff;
        color: #fff;
        font-size: 1.35rem;
        line-height: 46px;
        height: 50px;
        width:50px;
        right: -56px;
        &:hover {
            background: #fff;
        }
    }
    .mediabank-focused-close label {
        text-indent:-9999px;
    }
    .library-record-image {
        float: right;
    }
    
}