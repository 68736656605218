.blog,
.agenda {
    .blog-item {
        color: white;
        height: 300px;
        margin: 20px 0 0 0;
        position: relative;
        overflow: hidden;
        box-shadow: $blocks-shadow;
        min-height: 300px;
        a {
            color: $color-blue;
        }
        h2 {
            @extend .white-description-tittle;
            @include breakpoint(small only) {
                line-height: 1.2rem;
                a {
                    display: block;
                    margin-bottom: 14px;
                }
            }
        }
        img {
            width: 100%;
        }
        p {
            display: none;
        }
    }
    .subheading-category {
        display: block;
        width: 100%;
        padding: 8px 20px 12px;
        background-color: $color-white;
        color: $color-blue;
        font-size: rem-calc(32);
        box-shadow: $blocks-shadow;
    }
    //PAGINATION
    .pagination {
        text-align: center;
        margin: 20px 0;
        ul {
            box-shadow: $blocks-shadow;
            display: inline-block;
            margin: 6px auto 8px;
            padding: 3px 8px 5px;
            background-color: #fff;
            a,
            span {
                padding: 3px 6px;
                font-size: rem-calc(16);
            }
            span {
                opacity: 0.75;
            }
            a {
                color: $color-blue;
            }
            .pagination-start,
            .pagination-end {
                display: none;
            }
        }
        .counter {
            display: none;
        }
    }
}

.agenda {
    .blog-item {
        p {
            display: block;
            color: #000;
            position: absolute;
            bottom: 8px;
            left: 18px;
            font-size: rem-calc(16) !important;
        }
        h2 {
            padding: 16px 20px 36px;
        }
    }
}

.blog-item:hover h2 a {
    color: $color-gray-black;
}

.white-description-tittle {
    @extend %block-basic; //see elements.scss
    background: $color-white;
    &::before {
        background: url('../../svg/arrow-up.svg') no-repeat;
    }
}

.box-link a {
    font-size: 0px;
    height: 268px;
    width: 100%;
    position: absolute;
    z-index: 800;
    h2:hover {
        z-index: 888;
        position: relative;
    }
}

//AGENDA
.main-content .agenda {
    .blog-item h2 {}
}

//MEDIA QUERIES
@media all and (max-width: 800px) and (min-width:0px) {
    .blog .blog-item,
    .agenda .blog-item {
        height: 190px !important;
        min-height: 0;
        h2 {
            font-size: rem-calc(18);
            padding: 12px 16px 20px;
        }
    }
}

@media all and (max-width:1024px) and (min-width:801px) {
    .blog .blog-item,
    .agenda .blog-item {
        height: 230px !important;
        min-height: 0;
        h2 {
            font-size: rem-calc(18);
            padding: 12px 16px 20px;
        }
    }
}